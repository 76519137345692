


































import {Component, Prop, Vue} from 'vue-property-decorator'
import config from '@/config'
import {client} from "@/utils/oss";


@Component
export default class BtnComplexUploade extends Vue {
  $refs!: { upload: any }
  @Prop({default: 11}) limit:number = 11
  @Prop({default: true}) multiple!: boolean
  @Prop({default: ''}) data!: any
  @Prop() beforeUpload!: any
  @Prop() onSuccess!: any
  @Prop() onRemove !:any
  @Prop() fileList !:any
  action = `${config.baseURL}/adminapi/upload/lightFile`;
  version = config.version;
  tagName: any = ''
  // 进度条状态
  progressPercent: number = 0

  get showProgress() {
    return this.progressPercent > 0 && this.progressPercent < 100
  }
  //TODO
  async uploadFile(file: any) {

    // 获取要上传的文件
    const uploadFile = file.file
    const suffix = file.file.name.split('.').pop()
    // 获取扩展名
    const uuid = new Date().getTime() + Math.random().toString(36).substr(2)
    const path = 'uploads/light/' + uuid + '.' + suffix
    let that = this;
    const res = await client.multipartUpload(path, uploadFile, {
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Encoding': 'utf-8',
        'x-oss-forbid-overwrite': 'true'
      },
      progress: function (p: any, checkpoint: any) {
        that.progressPercent = Math.floor(p * 100)
      }
    })
    return {
      res,
      file
    }
  }

  //每次上传验证上传的文件数量
  checkFileLenght(files:any, fileList:any)
  {

    //判断是否重名

    var j =0;
    for(var i =0 ; i< fileList.length;i++){

      if(files.name == fileList[i].name){
        j++;
        if(j > 1){
          fileList[i] = [];
          this.$message.warning('上传文件存在重复名称!,请修改好上传');
          return false
        }
      }

    }




    if(fileList.length > 11){
      //this.$message.warning(`当前限制选择 11 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
      this.$message.warning('需上传11个参数内容');
      return false;
    }

  }

  //超出限制购置
  handleExceed(files:any, fileList:any) {

        //this.$message.warning(`当前限制选择 11 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
        this.$message.warning('需上传11个参数内容');
        return false
  }

      // 上传之前
  beforeComplexUpload(file: any) {


    const suffixJpg = file.name.split('.').shift()
    if(suffixJpg == ""){
      return false
    }
    const suffixTpl = suffixJpg.split('.').shift()
    if(suffixTpl == ""){
      return false
    }
    const suffix = suffixTpl.split('_').pop()
    if(suffix == ""){
      return false
    }

    const ext = file.name.split('.').pop()

    //检测文件名是否正确
    var sufArr = [
    '775','780','785','790','795',
            '800','805','810','815','820','825'
    ];
    if(sufArr.includes(suffix)){

    }else{

     this.$message.error('请正确编辑文件命名!')
      return false;
    }




    //获取后缀名，是否符合
    var extArr = [
      'jpg','png','mp4','jpeg'
    ];

    if(extArr.includes(ext)){


      return true;
    }else{

     this.$message.error('您上传的文件后缀名不符合要求!')
      return false;
    }
    console.log(file,suffix);
    return false;

  }


}
