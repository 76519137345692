




















































































































































































































































































import config from '@/config'
import { ModelFbx, ModelObj } from 'vue-3d-model'
import { Component, Watch } from 'vue-property-decorator'
import { apiEditMaterial, apiGetFormatTopic, apiNewMaterial } from '@/api/goods'
import MaterialSelect from '@/components/material-select/index.vue'
import BtnSingleUploader from '@/components/upload/BtnSingleUploader.vue'
import BtnComplexUploade from '@/components/upload/BtnComplexUploade.vue'
import * as _ from 'lodash'
import { Loading } from 'element-ui'
import { commonConfirm, goBackConfirm } from '@/utils/confirm'
import { deleteOSSFile } from '@/utils/oss'
import BtnDialogUploader from '@/components/upload/DialogUploader.vue'
import BeforeUploadMixins from '@/mixin/before-uploader'
import { mixins } from 'vue-class-component'

@Component({
  components: {
    MaterialSelect,
    BtnSingleUploader,
    BtnDialogUploader,
    ModelFbx,
    ModelObj,
    BtnComplexUploade
  }
})
export default class MaterialManage extends mixins(BeforeUploadMixins) {
  $refs!: { video: any, baseForm: any,upload:any }
  isUpload = 0
  topicList: any = []
  action = `${config.baseURL}/adminapi/upload/lightFile`
  version = config.version
  baseForm: any = {
    tableData: []
  }
  complexData:any = [];
  //内容参数选择标识

  radio:string="0"

  rules: any = {
    name: [
      { required: true, message: '请输入素材名称', trigger: 'blur' }
    ],
    tpl_model:[
      {required: true, message: '请上传光场3D', trigger: ['blur', 'change']}
    ]

  }
  //展示的空数据
  fileList:any = []

  rowClass () {
    return 'text-align:center'
  }

  @Watch('isUpload')
  onIsUploadChange (newVal: any) {
    if (newVal === 0) {
      Loading.service({ fullscreen: true }).close()
    }
  }

  addNewOne () {
    this.baseForm.tableData.push({
      name: '',
      image: '',
      goods_light_uri: '',
      goods_light_uri_name: '',
      topic_id: '',
      content: '',
      goods_image: [],
      status: 1,
      sort: 0,
      extend: '',
      extend_name: '',
      is_video: 0,
      spec_type: 1,
      gif_models: '',
      gif_models_name: '',
      row_num: 1,
      col_num: 1,
      complex:"0",
      goods_tpl_model:[],//批量上传

    })
  }

  /***
   * 提交
   */
  newMaterial () {

    // 如果没有添加数据，就提示
    if (this.baseForm.tableData.length === 0) return this.$message.error('请添加数据！')
    // 如果有数据，便验证
    this.$refs.baseForm.validate((valid: boolean) => {
      // 验证不通过不继续
      if (!valid) return
      commonConfirm('确定保存当前内容吗？', '保存提示', async () => {
        // 判断当前是否是修改,调用不同接口

        if (this.$route.params.data) {
          await apiNewMaterial({
            data: this.baseForm.tableData
          })
        } else {
          //编辑数据
          await apiEditMaterial({
            data: this.baseForm.tableData
          })
        }
        this.$message.success('保存成功！')
        this.$router.go(-1)
      })

    })
  }

  // 取消返回上一层
  cancelSubmit () {
    // 如果没有数据，直接返回，有数据的话判断是不是编辑，是编辑也直接返回
    if (this.baseForm.tableData.length === 0) {
      this.$router.go(-1)
      return
    } else if (this.$route.params.data) {
      return goBackConfirm(() => {
        this.$router.go(-1)
      })
    }
    goBackConfirm(async () => {
      const url: any = []
      this.baseForm.tableData.forEach((item: any) => {
        url.push(item.goods_light_uri)
        url.push(item.extend)
        url.push(item.image)
      })
      // 退出时删除所上传的文件，防止有垃圾数据
      await deleteOSSFile(url)
      this.$router.go(-1)
    })
  }

  // 获取所有主题
  async getTopic (value: any) {
    const {
      audio,
      calendar,
      paint
    } = await apiGetFormatTopic({
      search: value
    })
    this.topicList = [{
      label: '画册模式',
      options: { ...paint }
    }, {
      label: '音箱模式',
      options: { ...audio }
    }, {
      label: '台历模式',
      options: { ...calendar }
    }]
  }

  // 删除行
  deleteRow (row: any) {
    this.$confirm('确定删除当前的素材吗?', '删除提示').then(async () => {
      await this.baseForm.tableData.splice(row.$index, 1)
      this.$message({
        type: 'success',
        message: '删除成功!'
      })
    }).catch(() => {
    })
  }

  // OSS上传
  async uploadFile (file: any) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const OSS = require('ali-oss')
    const client = new OSS({
      accessKeyId: 'LTAI5t6CTbjsxAa1kz4x3RCp',
      accessKeySecret: 'eNlsZparfM5VkW3OSQJ9MkbH0UJH8D',
      // stsToken: '',
      region: 'oss-cn-qingdao',
      bucket: 'imagetru3d'
    })
    // 获取要上传的文件
    const uploadFile = file.file
    const suffix = file.file.name.split('.').pop()
    // 获取扩展名
    const uuid = new Date().getTime() + Math.random().toString(36).substr(2)
    const path = 'uploads/light/' + uuid + '.' + suffix
    const res = await client.multipartUpload(path, uploadFile, {
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Encoding': 'utf-8',
        'x-oss-forbid-overwrite': 'true'
      }
    })
    return {
      res,
      file
    }
  }


  goUp () {
    const data: any = this.$route.params.data[0]
    this.$router.push({
      name: 'material_manage',
      params: {
        data: data.topic_id
      }
    })
  }

  /**
   * 重新上传
   * @param type 0：封面图；1：光场；2：手机端模型
   * @param index 当前行索引
   */
  reUpload (type: number, index: number) {
    this.$confirm('确定重新上传吗？', '删除提示').then(() => {
      switch (type) {
        case 1:
          this.baseForm.tableData[index].goods_light_uri = ''
          break
        case 2:
          this.baseForm.tableData[index].extend = ''
          break
      }
    }).catch(() => {
    })
  }

  // 3d视频批量上传成功
  handleThreeDSuccess (response: any) {
    const threeDFullName = response.res.res.requestUrls[0].split('_').pop()
    let isHave = false
    this.baseForm.tableData.forEach((item: any) => {
      const imageFullName = item.image.split('_').pop()
      const imgName = imageFullName.split('.')[0]
      const threeDName = threeDFullName.split('.')[0]
      if (imgName === threeDName) {
        item.goods_light_uri = response.res.res.requestUrls[0].split('?')[0]
        isHave = true
      }
    })
    if (!isHave) {
      this.baseForm.tableData.push({
        name: '',
        image: '',
        goods_light_uri: response.res.res.requestUrls[0].split('?')[0],
        topic_id: '',
        content: '',
        goods_image: [],
        status: 1,
        sort: 0,
        extend: '',
        is_video: 0,
        spec_type: 1,
        complex:"0",
        goods_tpl_model:[]
      })
    }
  }

  // 主题封面gif图片上传
  coverUploadSuccessGif (response: any) {
    const { index } = response.file.data
    const { requestUrls } = response.res.res
    const { method } = response.file.data
    switch (method) {
      case 'cover':
        this.baseForm.tableData[index].gif_models = requestUrls[0].split('?')[0]
        break
    }
  }

  // 表格内3D视频上传成功
  handleVideoSuccess (res: any) {
    const { index } = res
    const { url } = res
    this.baseForm.tableData[index].goods_light_uri = url
  }

  // 表格内手机端模型上传成功
  handleExtendSuccess (res: any) {
    const { index } = res
    const { url } = res
    this.baseForm.tableData[index].extend = url
  }

  // 批量上传手机端模型成功
  handleExtendsSuccess (response: any) {
    const extendFullName = response.res.res.requestUrls[0].split('_').pop()
    let isHave = false
    this.baseForm.tableData.forEach((item: any) => {
      const imageFullName = item.image.split('_').pop()
      const imgName = imageFullName.split('.')[0]
      const extendName = extendFullName.split('.')[0]
      if (imgName === extendName) {
        item.extend = response.res.res.requestUrls[0].split('?')[0]
        isHave = true
      }
    })
    if (!isHave) {
      this.baseForm.tableData.push({
        name: '',
        image: '',
        goods_light_uri: '',
        topic_id: '',
        content: '',
        goods_image: [],
        status: 1,
        sort: 0,
        extend: response.res.res.requestUrls[0].split('?')[0],
        is_video: 0,
        spec_type: 1,
        gif_models: '',
        gif_models_name: '',
        complex:"0",
        goods_tpl_model:[]
      })
    }
  }

  // 素材2D成功
  onTwoUploadSuccess (response: any) {
    // const {method} = response.file.data
    const index = response.file.data.$index
    const { requestUrls } = response.res.res

    this.baseForm.tableData[index].goods_image.push(requestUrls[0].split('?')[0])

  }

  /**
   * 编辑页面数据渲染
  */
  async mounted () {
    await this.getTopic('')
    if (this.$route.params.data) {
      const OldData: any = this.$route.params.data
      for (let i = 0; i < OldData.length; i++) {
        if (OldData[i].topic_id === 0) {
          OldData[i].topic_id = ''
        }
        this.baseForm.tableData.push({
          id: OldData[i].id,
          name: OldData[i].name,
          topic_id: OldData[i].topic_id,
          image: OldData[i].image,
          goods_light_uri: OldData[i].goods_light_uri,
          content: OldData[i].content,
          goods_image: OldData[i].goods_image,
          gif_models: OldData[i].gif_models,
          status: 1,
          sort: 0,
          extend: OldData[i].extend,
          is_video: 0,
          spec_type: 1,
          extend_name: OldData[i].extend_name,
          goods_light_uri_name: OldData[i].goods_light_uri_name,
          col_num: OldData[i].col_num,
          row_num: OldData[i].row_num,
          complex:OldData[i].complex,
          goods_tpl_model:OldData[i].goods_image_tpl_model
        })
      }
    }
  }

  deleteGoodsImage (index: any, imgIndex: any) {
    this.$confirm('确定删除吗？', '删除提示').then(() => {
      this.baseForm.tableData[index].goods_image.splice(imgIndex, 1)
    }).catch(() => {
    })
  }

  // 手机模型上传成功
  onExtendUploadSuccess (response: any) {
    const name = response.file.file.name
    const model = response.res.res.requestUrls[0].split('?').shift()
    if (response.file.data) {
      const index = response.file.data.$index
      this.baseForm.tableData[index].extend = model
      this.baseForm.tableData[index].extend_name = name
      return
    }
    this.baseForm.tableData.push({
      name: '',
      image: '',
      goods_light_uri: '',
      goods_light_uri_name: '',
      topic_id: '',
      content: '',
      goods_image: [],
      status: 1,
      sort: 0,
      extend: model,
      extend_name: name,
      is_video: 0,
      spec_type: 1,
      gif_models: '',
      gif_models_name: '',
      complex:"0",
      goods_tpl_model:[],
    })
  }

  // 光场3D上传成功
  onThreeUploadSuccess (response: any) {
    const name = response.file.file.name
    const three = response.res.res.requestUrls[0]
    if (response.file.data) {
      const index = response.file.data.$index
      this.baseForm.tableData[index].goods_light_uri = three
      this.baseForm.tableData[index].goods_light_uri_name = name
      return
    }
    this.baseForm.tableData.push({
      name: '',
      image: '',
      goods_light_uri: three,
      goods_light_uri_name: name,
      topic_id: '',
      content: '',
      goods_image: [],
      status: 1,
      sort: 0,
      extend: '',
      extend_name: '',
      is_video: 0,
      spec_type: 1,
      gif_models: '',
      gif_models_name: '',
      complex:"0",
      goods_tpl_model:[],
    })
  }

  //光场3d批量上传回调
  onComoplexUploadSuccess(response:any)
  {


    const name = response.file.file.name
    const three = response.res.name;//文件名称地址
    if (response.file.data) {
      const index = response.file.data.$index

      this.baseForm.tableData[index].goods_tpl_model.push({
        name:name,
        tpl_model_url:three,
        url_name:name,
        url:three,
        url_sort:(name.split('.').shift()).split('_').pop(),//排序 名称去掉后缀名  const suffix = name.split('.').shift().split('_').pop()
    });
      // {complex:[{},{}]}

      return
    }
  }

  //光场3d批量上传删除触发事件
  onComoplexRemove(file:any,fileList:any)
  {


      var file_name = file.name;

      for(var i =0;i< this.baseForm.tableData.length;i++){

        for(var j = 0; j< this.baseForm.tableData[i].goods_tpl_model.length;j++){

          if(this.baseForm.tableData[i].goods_tpl_model[j].name == file_name){//移除相关的文件名称

              this.baseForm.tableData[i].goods_tpl_model.splice(j,1);//删除
              break;
          }
        }

      }

  }

  // 图片上传成功
  onImagesUploadSuccess (response: any) {
    const img = response.res.res.requestUrls[0].split('?')[0]
    if (response.file.data) {
      return this.baseForm.tableData[response.file.data.$index].image = img
    }
    this.baseForm.tableData.push({
      name: '',
      image: img,
      goods_light_uri: '',
      goods_light_uri_name: '',
      topic_id: '',
      content: '',
      goods_image: [],
      status: 1,
      sort: 0,
      extend: '',
      extend_name: '',
      is_video: 0,
      spec_type: 1,
      complex:"0",
      goods_tpl_model:[]
    })
  }

  //参数radio选中方法
  onChangeRadio(val:string)
  {

    this.radio=val;

  }

  removeTplModel(index:number){
    console.log("删除的索引",index)
    this.baseForm.tableData[index].goods_tpl_model = [];
    this.fileList[index] = [];
  }

  /**
    页面初始化
  */
  created()
  {

    if(JSON.stringify(this.$route.params) == '{}'){
      return false;
    }

    for(var i= 0;i<this.$route.params.data.length;i++ ){

      // this.fileList.push(this.$route.params.data[i].goods_image_tpl_model);
      this.fileList.push(this.$route.params.data[i]['goods_image_tpl_model']);



    }



  }


}
